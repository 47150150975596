import { LoginPage, LogoutPage, NoAccess, SettingsPage } from '@moveup/app-core/views';

const AuthAdminDashboard = () => import('@/views/AuthAdminDashboard.vue');

const UserEdit = () => import('@/views/UserEdit.vue');
const UsersList = () => import('@/views/UsersList.vue');

const ApplicationEdit = () => import('@/views/ApplicationEdit.vue');
const ApplicationNew = () => import('@/views/ApplicationNew.vue');
const ApplicationsList = () => import('@/views/ApplicationsList.vue');

import { checkAuth } from '@moveup/app-core/auth';

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: LoginPage,
	},
	{
		path: '/logout',
		name: 'Logout',
		component: LogoutPage,
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: NoAccess,
	},
	{
		path: '/dashboard',
		name: 'AuthAdminDashboard',
		component: AuthAdminDashboard,
		beforeEnter: checkAuth,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: SettingsPage,
		beforeEnter: checkAuth(['user:write', 'user:write-self']),
	},
	{
		path: '/users',
		name: 'UsersList',
		component: UsersList,
		beforeEnter: checkAuth(['user:read']),
	},
	{
		path: '/users/page/:page',
		name: 'UsersPage',
		component: UsersList,
		beforeEnter: checkAuth(['user:read']),
	},
	{
		path: '/user/:id',
		name: 'UserEdit',
		component: UserEdit,
		beforeEnter: checkAuth(['user:write']),
	},
	{
		path: '/applications',
		name: 'ApplicationsList',
		component: ApplicationsList,
		beforeEnter: checkAuth(['application:read']),
	},
	{
		path: '/applications/page/:page',
		name: 'ApplicationsPage',
		component: ApplicationsList,
		beforeEnter: checkAuth(['application:read']),
	},
	{
		path: '/application/:id',
		name: 'ApplicationEdit',
		component: ApplicationEdit,
		beforeEnter: checkAuth(['application:write']),
	},
	{
		path: '/application',
		name: 'ApplicationNew',
		component: ApplicationNew,
		beforeEnter: checkAuth(['application:write']),
	},
];

export default routes;
