const app = {
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'moveUP Auth Admin',
		// all titles will be injected into this template
		titleTemplate: '%s | Auth Admin',
	},
	created() {
		// startup logic goes here
	},
};

export { app };
